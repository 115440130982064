import {RegisterViewModel} from "./RegisterViewModel";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import LoginHeader from "../../components/login_header/LoginHeader";
import styles from "./register.module.css";
import {InputText} from "../../components/input/InputText";
import ButtonAccent from "../../components/button/ButtonAccent";
import {observer} from "mobx-react";

interface Props {
    viewModel: RegisterViewModel
}

const RegisterCodeScreen = ({viewModel}: Props) => {

    const navigate = useNavigate()
    const [code, setCode] = useState('');

    return <div>
        <LoginHeader onBackClick={() => navigate(-1)} title={`Код из письма`}/>
        <div className={styles.container}>
            <p className={styles.description}>{'На указанный Вами адрес электронной почты был выслан код. Введите его в поле ниже'}</p>
            <InputText
                placeholder='Код'
                onChange={text => setCode(text)}/>

            <ButtonAccent
                buttonStyle={{marginTop: 32}}
                onClick={() => viewModel.activate(code)}
                title={'Активировать'}
                isLoading={viewModel.state.activateRequest.isLoading}
                isEnabled={Boolean(code.length)}/>
        </div>
    </div>
}

export default observer(RegisterCodeScreen)
