import {Async, failure, initial, loading, success} from "../../data/entity/Async";
import {makeAutoObservable} from "mobx";
import {repository} from "../../data/repo/Repository";

interface State {
    registerRequest: Async<boolean>
    activateRequest: Async<boolean>
    email: string
    company: string
}

export class RegisterViewModel {
    registerRequest: Async<boolean> = initial()
    activateRequest: Async<boolean> = initial()
    private email: string = ''
    private company: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            registerRequest: this.registerRequest,
            activateRequest: this.activateRequest,
            email: this.email,
            company: this.company
        }
    }

    register(company: string, email: string, password: string) {
        this.registerRequest = loading()
        repository.register(company, email, password)
            .then(() => {
                this.email = email
                this.registerRequest = success(true)
            })
            .catch((e) => {
                this.registerRequest = failure(e)
            })
    }

    activate(code: string) {
        this.activateRequest = loading()
        repository.activate(this.email, code)
            .then((company) => {
                this.activateRequest = success(true)
            })
            .catch((e) => this.activateRequest = failure(e))
    }
}
